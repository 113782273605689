import _ from 'lodash'

export function formatMoney(n) {
    return "₹" + (Math.ceil(parseInt(n) * 100) / 100).toLocaleString();
}

export function percentage(mrp,price){
    return Math.ceil(((parseInt(mrp)-parseInt(price))/parseInt(mrp))*100)
}

export const renderName = (details)=>{
    let name = details.name
    _.map(details.variations.options,(value,key)=>{
        if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
            name = name + " " + `(${value})`
        }
    })
    return name
}
export const renderName2 = (product,options)=>{
    let name = product.name
    _.map(options,(value,key)=>{
        if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
            name = name + " " + `(${value})`
        }
    })
    return name
}
export const cartTotal = (cart)=>{
    let total = 0
    cart.map(item=>{
        total = total + (parseInt(item.variations.item.options.price) * item.variations.quantity)
    })
    return formatMoney(total);
}

export const cartMrpTotal = (cart)=>{
    let total = 0
    cart.map(item=>{
        total = total + (parseInt(item.variations.item.options.mrp) * item.variations.quantity)
    })
    return formatMoney(total);
}

export const cartDiscountTotal = (cart)=>{
    let total = 0
    let mrptotal = 0
    cart.map(item=>{
        total = total + (parseInt(item.variations.item.options.price)* item.variations.quantity)
    })
    cart.map(item=>{
        mrptotal = mrptotal + (parseInt(item.variations.item.options.mrp)* item.variations.quantity)
    })
    return formatMoney(mrptotal-total);
}

export const cartDiscountPercantage = (cart)=>{
    let total = 0
    let mrptotal = 0
    cart.map(item=>{
        total = total + (parseInt(item.variations.item.options.price) * item.variations.quantity)
    })
    cart.map(item=>{
        mrptotal = mrptotal + (parseInt(item.variations.item.options.mrp)* item.variations.quantity)
    })
    return percentage(mrptotal,total);
}


export const orderSubTotal = (items)=>{
    let subTotal = 0;
    items.map(item=>{
        subTotal =subTotal + item.quantity * parseInt(item.details.options.mrp)
    })
    return formatMoney(subTotal)
}

export const orderTotal = (items)=>{
    let total = 0;
    items.map(item=>{
        total =total + item.quantity * parseInt(item.details.options.price)
    })
    return formatMoney(total)
}

export const orderDiscount = (items)=>{
    let total = 0;
    let subTotal = 0
    items.map(item=>{
        total =total + item.quantity * parseInt(item.details.options.price)
        subTotal =subTotal + item.quantity * parseInt(item.details.options.mrp)
    })
    return formatMoney(subTotal - total)
}

export const renderPriceWithoutGst = (price,gst)=>{
    let gstamount = (price/100)*gst
    return formatMoney(price-gstamount)
}

export const renderTenure = (sr,price)=>{
    if(sr===1 || sr==="1"){
        return {months:3,downPayment:0,monthsEmi:Math.ceil(price/3)}
    }else if(sr===2 || sr==="2"){
        return {months:6,downPayment:0,monthsEmi:Math.ceil(price/6)}
    }else if(sr===3 || sr==="3"){
        return {months:6,downPayment:Math.ceil((price/8)*2),monthsEmi:Math.ceil(price/8)}
    }
    else if(sr===4 || sr==="4"){
        return {months:6,downPayment:Math.ceil((price/9)*3),monthsEmi:Math.ceil(price/9)}
    }
}

export const renderTenureInterest = (sr,price)=>{
    if(sr===1 || sr==="1"){
        return {months:3,downPayment:0,monthsEmi:Math.ceil(price/3),interest:Math.round((((price*16)/100)/12)*3)}
    }else if(sr===2 || sr==="2"){
        return {months:6,downPayment:0,monthsEmi:Math.ceil(price/6),interest:Math.round((((price*15)/100)/12)*6)}
    }else if(sr===3 || sr==="3"){
        return {months:6,downPayment:Math.ceil((price/9)*3),monthsEmi:Math.ceil(price/8),interest:Math.round((((price*15)/100)/12)*6)}
    }
    else if(sr===4 || sr==="4"){
        return {months:6,downPayment:Math.ceil((price/8)*2),monthsEmi:Math.ceil(price/9),interest:Math.round((((price*15)/100)/12)*6)}
    }
}

export const renderTenureString = (sr,price)=>{
    if(sr===1){
        return `3 Months No Cost + ₹0 Down Payment EMI by CleverPe`
    }else if(sr===2){
        return `6 Months No Cost + ₹0 Down Payment EMI by CleverPe`
    }else if(sr===3){
        return `6 Months No Cost + ₹${Math.ceil((price/8)*2)} Down Payment EMI by CleverPe`
    }
    else if(sr===4){
        return `6 Months No Cost + ₹${Math.ceil((price/9)*3)} Down Payment EMI by CleverPe`
    }
}

export const renderTenureStringInterest = (sr,price)=>{
    if(sr===1){
        return `3 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*16)/100)/12)*3)}) + ₹0 Down Payment`
    }else if(sr===2){
        return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹0 Down Payment`
    }else if(sr===3){
        return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round(((((price-Math.ceil((price/9)*3))*15)/100)/12)*6)}) + ₹${Math.ceil((price/9)*3)} Down Payment`
    }
    else if(sr===4){
        return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round(((((price-Math.ceil((price/8)*2))*15)/100)/12)*6)}) + ₹${Math.ceil((price/8)*2)} Down Payment`
    }
}


// export const renderTenureStringInterest = (sr,price)=>{
//     if(sr===1){
//         return `3 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*16)/100)/12)*3)}) + ₹0 Down Payment`
//     }else if(sr===2){
//         return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹0 Down Payment`
//     }else if(sr===3){
//         return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹${Math.ceil((price/9)*3)} Down Payment`
//     }
//     else if(sr===4){
//         return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹${Math.ceil((price/8)*2)} Down Payment`
//     }
// }

export const renderInterestPercentage = (sr,price)=>{
    if(sr===1){
        return 4
    }else if(sr===2){
        return 7.50
    }else if(sr===3){
        return 5
    }
    else if(sr===4){
        return 5.63
    }
}

export const renderInterestAmount = (sr,price)=>{
    if(sr===1){
        return Math.round((price/100)*4)
    }else if(sr===2){
        return Math.round((price/100)*7.50)
    }else if(sr===3){
        return Math.round((price/100)*5)
    }
    else if(sr===4){
        return Math.round((price/100)*5.63)
    }
}