import React from 'react'
import "./Orders.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import {connect} from 'react-redux'
import axios from 'axios'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as FileSaver from 'file-saver';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//import { JsonToExcel } from "react-json-to-excel";
import {
    Autocomplete,
    Button,
    Container,
    Stack,
    TextField,
  } from "@mui/material";
function Merchants(props) {
    const [display,setDisplay]=React.useState(false)
    const [data,setData]=React.useState([])
    const [selectedDate, setSelectedDate] = React.useState("");
    const [selectedDate2, setSelectedDate2] = React.useState("");
    const options = ['Option 1', 'Option 2'];
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [allMerchants,setAllMerchants] = React.useState([{name:"First Entry"}])
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    React.useEffect(()=>{
        console.log(props.user.userInfo.groupId)
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/completed/groupId`,{groupId:props.user.userInfo.groupId},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            setData(arr)
        })
        .catch(err=>{
            console.log(err)
        })
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/org/members`,{groupId:props.user.userInfo.groupId},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            setAllMerchants(arr)
        })
        .catch(err=>{
            console.log(err)
        })
    },[])

    console.log(checked)

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={4} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>
        <h2 className="mb-3">Export Transaction Dump (Excel)</h2>

            <form className="form">

            <div className="row m-auto">
             <section className="my-2 col-6">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedDate(newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />} />
              </LocalizationProvider>
            </section>

            <section className="my-2 col-6">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="dd/MM/yyyy"
                  value={selectedDate2}
                  onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedDate2(newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />} />
              </LocalizationProvider>
            </section>
            </div>
        <section className="my-4">
        <Autocomplete
        // value={value}
        disabled={checked}
        onChange={(event, newValue) => {
        console.log(newValue)
          setValue(newValue);
        }}
        // inputValue={inputValue}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        id="controllable-states-demo"
        options={allMerchants}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Select Merchant" />}
      />
    <FormGroup className="my-2">
    <FormControlLabel control={    <Checkbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />} label="All Stores" />
    </FormGroup>
      </section>
            


            <div className="my-3" style={{textAlign:"center"}}>
              <p className="my-3">Note: If you want to download report for all merchant's then don't select any merchant from the dropdown above</p>
            <Button onClick={async ()=>{
                const response = await axios({
                  url: `${process.env.REACT_APP_DEVELOPMENT}/api/order/settlement`,
                  method: 'POST',
                  responseType: 'blob',
                  data:{date:selectedDate,date1:selectedDate2,merchantId:checked?null:value._id,type:checked?0:1},
                  headers:{token:props.user.user}
                });
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, 'settlement.xlsx');
            // axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/settlement`,{groupId:props.user.userInfo.groupId,date:selectedDate,date1:selectedDate2,merchantId:value._id},{headers:{token:props.user.user}})
            // .then(res=>{
            //     console.log(res)
            //     // let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            //     // setData(arr)
            // })
            // .catch(err=>{
            //     console.log(err)
            // })
            }} variant="contained">Download Excel</Button>

            </div>
            </form>
            <h1>Completed Orders</h1>
            <div style={{ height: '50vh', width: '100%' }}>
                <DataGrid
                    rows={data}
                    columns={columns2}
                    autoPageSize
                    onRowClick={(item,ev)=>props.history.push('/orderinfo',item.row._id)}
                />
            </div>

             </div>
    </div>
    )
}


const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'customerMobile', headerName: 'Customer Mobile No',valueGetter:(param)=>param.row.customer.mobileNo,width:150},
    { field: 'reciept', headerName: 'Reciept',valueGetter:(param)=>param.row.reciept?param.row.reciept:"Not Uploaded",width:200},
    { field: 'Reciept Date', headerName: 'recieptUploadedAt',valueGetter:(param)=>!param.row.recieptUploadedAt?"Not Available":moment.parseZone(param.row.recieptUploadedAt).local().format("DD/MM/YY"),width:150},
    { field: 'status', headerName: 'Order Status',valueGetter:(param)=>param.row.status,width:150},
    { field: 'total', headerName: 'Total',valueGetter:(param)=>param.row.total,width:150},
    { field: 'items', headerName: 'Total Items',valueGetter:(param)=>param.row.items.length,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];

const mapStateToProps =({EventUser})=>{
    return {
      user:EventUser
    }
  }

export default connect(mapStateToProps)(Merchants)
